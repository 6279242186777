import Styles from "./styles";
import { layout } from "components";
import { useLanguage } from "hooks";

function Place() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const titleData = {
    id: "place_step_01",
    h2: languageQuarter ? "사업장" : "Our Workplaces",
    title: languageQuarter ? (
      <>
        여주 본사와 공장에서 국내사업을, <br />
        UAE 두바이에서 서남아시아와 유럽사업을, <br className="pcOnly" />
        베트남 빈둥에서 동남아시아 사업을 확장
      </>
    ) : (
      <>
        Headquarters: Yeoju, Gyeonggi-Do <br />
        Manufacturing : Yeoju, Gyeonggi-Do, and Daegu City <br />
        Marketing : Seoul Office <br />
        Southwest Asia/Europe Marketing : Dubai, UAE <br />
        Southeast Asia Marketing : Binh Duong, Vietnam <br />
      </>
    ),
    description: languageQuarter ? (
      <>
        ㈜에너토크는 경기도 여주에 본사와 제 1공장을 <br className="mbOnly" />
        두고 있으며, 여기에서 국내외 주요사업에 대한 의사결정과 주요 제품을
        생산하고 있습니다. <br />
        2022년에는 생산력 확충을 위해 대구에 제 2공장을 설립하였으며, 유능한
        인재 소싱을 통해 연구개발 기능도 강화하게 되었습니다. <br />
        UAE에서 서남아시아 지역의 영업을 이미 진행 중이며, 유럽으로의 진출
        교두보 역할을 추가할 예정이며, 베트남 빈둥 해외공장에서 원부자재 소싱,{" "}
        <br />
        마케팅 활동을 수행 중입니다.
      </>
    ) : (
      <>
        Enertork is headquartered in Yeoju, Gyeonggi-Do, where it is its first
        factory. Here, major decisions are made, and key products are
        manufactured. In 2022, to enhance production capacity, we established
        its second factory in Daegu and through effective talent recruitment, we
        have also strengthened its research and development capabilities.
        Enertork is currently conducting business operations in Southeast Asia
        from the UAE and plans to serve as a springboard to advance into Europe.
        Additionally, factory in Binh Duong, Vietnam is engaged in sourcing raw
        materials and conducting marketing activities.
      </>
    ),
  };

  const getMap = (src) => {
    return (
      <iframe
        src={src}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        width="100%"
        height="100%"
      ></iframe>
    );
  };

  const address = {
    place_step_01:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3175.1179980751567!2d127.59684746400947!3d37.26866538717046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca40f0da3eac5%3A0x7b95a03e789987e4!2z7JeQ64SI7Yag7YGs!5e0!3m2!1sko!2skr!4v1720080485954!5m2!1s",
    place_step_02:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.355370709529!2d128.422985585798!3d35.643615171646566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3565e108ca96cbad%3A0x2902579fe2d38ccb!2zKOyjvCnsl5DrhIjthqDtgawg64yA6rWs6rO17J6l!5e0!3m2!1sko!2skr!4v1720078421280!5m2!1s",
    place_step_03:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d450.19480274042445!2d127.04325297479932!3d37.5019101!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca5000e38d42f%3A0x98b661acca9fc87f!2zKOyjvCnsl5DrhIjthqDtgawg7ISc7Jq4!5e0!3m2!1sko!2skr!4v1720079110780!5m2!1s",
    place_step_04:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3915.5287757065967!2d106.68163527599432!3d11.073912889093538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174cfb62a171619%3A0xd5e6df510e930cb7!2sVSIP%20II%20Binh%20Duong!5e0!3m2!1sko!2skr!4v1718680440524!5m2!1s",
    place_step_05:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3175.1179980751567!2d127.59684746400947!3d37.26866538717046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca40f0da3eac5%3A0x7b95a03e789987e4!2z7JeQ64SI7Yag7YGs!5e0!3m2!1sko!2skr!4v1720080485954!5m2!1s",
  };

  const placeList = [
    {
      id: "place_step_01",
      title: languageQuarter ? "본사/여주공장" : "HO/Yeo-Ju Factory",
      function: languageQuarter
        ? [
            "- 본사 기능 : 의사결정 기능, 인사 관리 기능",
            <>
              - 생산 기능 : TM, TX 등 주력 제품 생산 및 품질관리 기능,{" "}
              <br className="mbOnly" />
              AS 기능
            </>,
          ]
        : [
            "- Headquarters Functions : Decision-Making, HR",
            <>
              - Manufacturing Functions : Production and Quality Control
              Functions for Core Products such as TM and TX, After-Sales (AS)
              Functions
            </>,
          ],
      direction: [
        languageQuarter
          ? "- Address : 경기도 여주시 세종대왕면 능여로 344"
          : "- Address : 344, Neungyeo-ro, Sejongdaewang-myeon, Yeoju-si, Gyenggi-Do, Korea",
        "- Tel : 031-880-2800",
        "- Fax : 031-881-5860",
      ],
    },
    {
      id: "place_step_02",
      title: languageQuarter ? "대구공장/연구소" : "Daegu Factory/R&D Center",
      function: languageQuarter
        ? [
            "- 생산 기능 : 수문권양기 등",
            <>
              - 연구 기능 : 기존 제품 성능 강화 연구 기능,{" "}
              <br className="mbOnly" />
              신제품 개발 기능 등
            </>,
          ]
        : [
            "- Manufacturing Functions :  sluice gate hoist, etc",
            <>
              - R&D Functions : Enhancement of Existing Product Performance,
              Development of New Products
            </>,
          ],
      direction: [
        languageQuarter
          ? "- Address : 대구광역시 달성군 구지면 국가산단대로28길 91"
          : "- Address : 91, Gukgasandan-daero 28-gil, Guji-myeon, Dalseong-gun, Daegu",
        "- Fax : 053-614-5863",
      ],
    },
    {
      id: "place_step_03",
      title: languageQuarter ? "서울 사무소" : "Seoul Office",
      function: languageQuarter
        ? [
            "- 영업 기능 : 영업 마케팅 기능",
            "- 재무관리 기능 : 회계, 재무 기능",
            "- 신사업 추진 기능 : 각종 신사업 추진 기능",
          ]
        : [
            "- Sales & Marketing Functions",
            "- Financial Management Functions",
            "- New Business",
          ],
      direction: [
        languageQuarter
          ? "- Address : 서울시 강남구 언주로 430 윤익빌딩 18층"
          : "- Address : 18F, Un-Ik bldg., 430, Eonju-ro Gangnam-gu, Seoul",
        "- Tel : 02-555-0883",
        "- Fax : 02-556-3026",
      ],
    },
    {
      id: "place_step_04",
      title: languageQuarter
        ? "베트남법인(ENERTORK VINA)"
        : "ENERTORK VINA : Vietnam Factory",
      function: languageQuarter
        ? [
            "- 생산 기능 : 액추에이터 관련",
            "- 소싱 기능 : 각 종 원부자재 등",
            "- 기타 : 영업기능, AS기능 확대 중",
          ]
        : [
            "- Manufacturing Functions",
            "- Sourcing Functions : Various Raw Materials, Parts, etc",
            "- Sales & Marketing, AS Functions",
          ],
      direction: [
        <>
          - Address : No. 1 VSIP II-A, Street 30, <br className="mbOnly" />
          Vietnam-Singapore Industrial Pack II-A, <br />
          Vinh Tan Commune, Tan Yuen Town, <br className="mbOnly" />
          Binh Duong Province, Vietnam
        </>,
        "- Tel : +84-0274-380-3180",
        "- Fax : +84-0274-380-3190",
      ],
    },
    {
      id: "place_step_05",
      title: languageQuarter ? "한우리복지재단" : "Hanwoori Welfare Foundation",
      function: languageQuarter
        ? ["- 여주지역 복지기금 지원", "- 대구지역 복지기금 지원"]
        : [
            "- Support for Welfare Fund for Needy Neighbors in Yeoju-si",
            "- Support for Welfare Fund for Needy Neighbors in Daegu-si",
          ],
      direction: [
        languageQuarter
          ? "- Address : 경기도 여주시 세종대왕면 능여로 344"
          : "- Address : 344, Neungyeo-ro, Sejongdaewang-myeon, Yeoju-si, Gyenggi-Do, Korea",
        "- Tel : 031-880-2800",
        "- Fax : 031-881-5860",
      ],
    },
  ];

  return (
    <Styles.Container>
      <layout.TitleContainer data={titleData} />

      <section className="placeSection">
        {placeList.map((item, index) => {
          return (
            <div id={item.id} className="place" key={"place" + index}>
              <div className="map">
                {getMap(
                  `${address[item.id]}${languageQuarter ? "ko" : "en"}!2skr`
                )}
              </div>

              <div className="right">
                <p className="title">{item.title}</p>

                <p className="listTitle">
                  {languageQuarter ? "주요 기능" : "Roles & Responsibilities"}
                </p>

                <ul>
                  {item.function.map((current, idx) => {
                    return (
                      <li className="functionList" key={"functionList" + idx}>
                        {current}
                      </li>
                    );
                  })}
                </ul>

                <p className="listTitle">
                  {languageQuarter ? "오시는 길" : "Location"}
                </p>

                <ul>
                  {item.direction.map((current, idx) => {
                    return (
                      <li className="directionList" key={"directionList" + idx}>
                        {current}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </section>
    </Styles.Container>
  );
}

export default Place;
