import Styles from "./styles";
import { Outlet } from "react-router-dom";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";
import { common, layout } from "components";

// img
import BgMainBanner from "resources/image/companyIntroduction/bg_main_banner.jpg";
import BgMainBannerMb from "resources/image/companyIntroduction/bg_main_banner_mb.jpg";

function CompanyIntroductionPage() {
  const isMobile = useMediaQuery({ query: "(max-width: 728px)" });
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const data = {
    img: isMobile ? BgMainBannerMb : BgMainBanner,
    title: languageQuarter ? "회사 소개" : "About Us",
    firstDepthList: [
      {
        text: languageQuarter ? "회사 정보" : "Our Information",
        link: "/company/introduction/info",
        secondDepthList: languageQuarter
          ? [
              { text: "회사 개요", focus: "company_step_01" },
              { text: "주요연혁", focus: "company_step_02" },
              { text: "조직도", focus: "company_step_03" },
              { text: "인사말", focus: "company_step_04" },
              { text: "보유특허/인증서", focus: "company_step_05" },
            ]
          : [
              { text: "Overview", focus: "company_step_01" },
              { text: "History", focus: "company_step_02" },
              { text: "Organization", focus: "company_step_03" },
              { text: "CEO Greeting", focus: "company_step_04" },
              { text: "Certification & Patents", focus: "company_step_05" },
            ],
      },
      {
        text: languageQuarter ? "ESG 경영" : "Our ESG Management",
        link: "/company/introduction/management",
        secondDepthList: languageQuarter
          ? [
              { text: "ESG 경영이란?", focus: "esg_step_01" },
              { text: "도입 필요성", focus: "esg_step_02" },
            ]
          : [
              { text: "ESG Management", focus: "esg_step_01" },
              { text: "Importance of ESG", focus: "esg_step_02" },
            ],
      },
      {
        text: languageQuarter ? "주요 고객사" : "Our Customers",
        link: "/company/introduction/client",
        secondDepthList: languageQuarter
          ? [
              { text: "석유, 가스", focus: "client_step_01" },
              { text: "발전", focus: "client_step_02" },
              { text: "상하수도", focus: "client_step_03" },
              { text: "기타", focus: "client_step_04" },
            ]
          : [
              { text: "Oil & Gas", focus: "client_step_01" },
              { text: "Power Plant", focus: "client_step_02" },
              { text: "Waterworks", focus: "client_step_03" },
              { text: "Others", focus: "client_step_04" },
            ],
      },
      {
        text: languageQuarter ? "사업장" : "Our Workplaces",
        link: "/company/introduction/place",
        secondDepthList: languageQuarter
          ? [
              { text: "본사/여주공장", focus: "place_step_01" },
              { text: "대구공장/연구소", focus: "place_step_02" },
              { text: "서울 사무소", focus: "place_step_03" },
              { text: "베트남법인(ENERTORK VINA)", focus: "place_step_04" },
              { text: "한우리복지재단", focus: "place_step_05" },
            ]
          : [
              { text: "HO/Factory", focus: "place_step_01" },
              { text: "Daegu Factory/R&D", focus: "place_step_02" },
              { text: "Seoul Office", focus: "place_step_03" },
              {
                text: "Vietnam Factory(ENERTORK VINA)",
                focus: "place_step_04",
              },
              { text: "Welfare Foundation", focus: "place_step_05" },
            ],
      },
      {
        text: languageQuarter ? "대리점" : "Our Agencies",
        link: "/company/introduction/agency",
        secondDepthList: languageQuarter
          ? [
              { text: "국내", link: "#agency01", focus: "agency_step_01" },
              { text: "해외", link: "#agency02", focus: "agency_step_02" },
            ]
          : [
              { text: "Domestic", link: "#agency01", focus: "agency_step_01" },
              { text: "Overseas", link: "#agency02", focus: "agency_step_02" },
            ],
      },
    ],
  };
  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Banner data={data} isMain={true} />

        <div className="inner">
          <common.SideMenu data={data} />

          <div className="narrowInner">
            <Outlet />
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default CompanyIntroductionPage;
